// 新增员工
<template>
  <div class="addStaff">
    <div class="addStaff_box b-c-f">
      <div class="">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/personage/staff' }"
            >员工管理
          </el-breadcrumb-item>
          <el-breadcrumb-item>编辑</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="addStaff_head p-t-30">
        <el-form
          ref="form"
          :rules="rules"
          :model="form"
          class="form"
          label-width="90px"
        >
          <ul class="addStaff_ul">
            <li>
              <div class="staffDetails_ul_head m-b-30">
                <span>个人信息</span>
              </div>

              <div class="form">
                <el-form-item label="头像:" prop="image">
                  <div class="form_head">
                    <img :src="form.image" alt="" v-if="form.image" />
                    <img
                      src="../../assets/image/personage/head.png"
                      alt=""
                      v-else
                    />
                    <input type="file" @input="changeHead" />
                  </div>
                </el-form-item>
                <el-form-item label="姓名:" prop="name">
                  <el-input
                    v-model="form.name"
                    maxlength="10"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="性别:">
                  <!-- <el-input v-model="form.sex" placeholder="请输入"></el-input> -->
                  <el-select v-model="form.sex" placeholder="请选择性别">
                    <el-option
                      v-for="item in sexList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="手机号:" prop="mobile">
                  <el-input
                    v-model="form.mobile"
                    maxlength="11"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证号:" prop="identity">
                  <el-input
                    v-model="form.identity"
                    maxlength="18"
                    placeholder="请输入身份证号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="年龄:">
                  <el-input v-model.number="form.age" maxlength="3" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="生日:">
                  <el-date-picker
                    v-model="form.birthday"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="现住:">
                  <el-input
                    v-model="form.xian_address"
                    placeholder="请输入现住址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="籍贯:">
                  <el-input
                    v-model="form.native_place"
                    placeholder="请输入籍贯"
                  ></el-input>
                </el-form-item>
                <el-form-item label="直系亲属:">
                  <el-input v-model="form.relatives" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="电话:">
                  <div class="addStaff_phone">
                    <div class="addStaff_phone_li flexs" v-for="(item,index) in phoneList" :key="index">
                      <el-input v-model="item.phone" maxlength="11" placeholder="请输入"></el-input>
                      <img @click="changePhone(index)" v-if="index == 0" src="../../assets/image/personage/add1.png" alt="" />
                      <img @click="changePhone(index)" v-else src="../../assets/image/personage/dele.png" alt="">
                    </div>
                  </div>
                </el-form-item>
              </div>
            </li>
            <li>
              <div class="staffDetails_ul_head m-b-30">
                <span>职位信息</span>
              </div>
              <div class="form">
                <el-form-item></el-form-item>
                <el-form-item label="职位:" prop="position">
                  <el-select v-model="form.position" placeholder="请选择职位">
                    <el-option
                      v-for="item in positionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="人事职位:" prop="renshi_position" v-if="form.position == 5">
                  <el-select v-model="form.renshi_position" placeholder="请选择职位">
                    <el-option
                      v-for="item in renshi_position"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="区域:" prop="city">
                  <el-cascader
                    size="large"
                    :options="regionList"
                    v-model="region"
                    placeholder="请选择所在区域"
                    @change="handleChangeCity"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="门店:" prop="dian_id" v-if="form.position != 1">
                  <el-select
                    :disabled="!form.city"
                    @change="getUserList"
                    clearable
                    v-model="form.dian_id"
                    placeholder="请选择门店"
                  >
                    <el-option
                      v-for="item in areaList"
                      :key="item.id"
                      :label="item.dian_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="经理:" prop="manger_id" v-if="form.position == 4">
                  <el-select :disabled="!form.dian_id" v-model="form.manger_id" placeholder="请选择经理">
                    <el-option
                      v-for="item in managerList"
                      :key="item.user_id"
                      :label="item.name"
                      :value="item.user_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="入职时间:" prop="position_time">
                  <el-date-picker
                    v-model="form.position_time"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="薪资模式:" prop="salary">
                  <el-select
                    clearable
                    v-model="form.salary"
                    placeholder="请输入薪资模式"
                  >
                    <el-option
                      v-for="item in salaryList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </li>
            <li>
              <div class="staffDetails_ul_head m-b-30">
                <span>职位信息</span>
              </div>
              <div class="identity_ul flex">
                <div class="identity_ul_li">
                  <div class="identity_txt">身份证正面:</div>
                  <div class="identity_ul_li_img">
                    <img
                      :src="form.identity_image_z"
                      alt=""
                      v-if="form.identity_image_z"
                    />
                    <img
                      v-else
                      src="../../assets/image/personage/identity_z.png"
                      alt=""
                    />
                    <input type="file" @input="uploadImg($event, 1)" />
                  </div>
                </div>
                <div class="identity_ul_li">
                  <div class="identity_txt">身份证反面:</div>
                  <div class="identity_ul_li_img">
                    <img
                      :src="form.identity_image_f"
                      alt=""
                      v-if="form.identity_image_f"
                    />
                    <img
                      v-else
                      src="../../assets/image/personage/identity_f.png"
                      alt=""
                    />
                    <input type="file" @input="uploadImg($event, 2)" />
                  </div>
                </div>
              </div>
              <div class="staffDetails_other m-t-20 m-b-20">
                <div class="identity_txt">劳动合同:</div>
                <div class="staffDetails_other_ul flexs flex-wrap">
                  <div
                    class="staffDetails_other_li"
                    v-for="(item, index) in contract_images"
                    :key="index"
                  >
                    <img :src="item" alt="" />
                    <img src="../../assets/image/publice/cancel.png" class="cancel" alt="" @click="cancelImg('contract_images',index)">
                  </div>
                  <div class="staffDetails_other_li">
                    <img src="../../assets/image/personage/add.png" alt="" />
                    <input type="file" @input="uploadImg($event, 3)" />
                  </div>
                </div>
              </div>
              <div class="staffDetails_other">
                <div class="identity_txt">其他:</div>
                <div class="staffDetails_other_ul flexs flex-wrap">
                  <div
                    class="staffDetails_other_li"
                    v-for="(item, index) in images"
                    :key="index"
                  >
                    <img :src="item" alt="" />
                    <img src="../../assets/image/publice/cancel.png" class="cancel" alt="" @click="cancelImg('images',index)">
                  </div>
                  <div class="staffDetails_other_li">
                    <img src="../../assets/image/personage/add.png" alt="" />
                    <input type="file" @input="uploadImg($event, 4)" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </el-form>
        <div class="addStaff_footer">
          <el-button @click="submitForm('form')">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    var changeMobile = (rule, value, callback) => {
      if (!value.match(/^(0|86|17951)?1[3456789]\d{9}$/))
        callback(new Error("请输入正确的手机号"));
      else callback();
    };

    var changeIdentity = (rule, value, callback) => {
      let _IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!value.match(_IDRe18)) callback(new Error("请输入正确身份证号"));
      else callback();
    };
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      form: {
        id:'',
        image: "",
        name: "",
        mobile: "",
        sex: "",
        age: "",
        identity: "",
        birthday: "",
        xian_address: "",
        native_place: "",
        relatives: "",
        relatives_mobile: "",
        position: "",
        renshi_position:'',
        dian_id: "",
        city: "",
        position_time: "",
        salary: "",
        identity_image_z: "",
        identity_image_f: "",
        contract_images: "",
        images: ""
      },
      phoneList:[
        {
          phone:''
        }
      ],//
      renshi_position:[
        {
          value: 1,
          label: "人事专员",
        },
        {
          value: 2,
          label: "人事主任",
        },
        {
          value: 3,
          label: "人事经理",
        }
      ],//人事职位
      contract_images: [], //合同
      images: [], //其他
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        image: [{ required: true, message: "请上传头像", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: changeMobile, trigger: "blur" },
        ],
        identity: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          { validator: changeIdentity, trigger: "blur" },
        ],
        position: [{ required: true, message: "请选择职位", trigger: "blur" }],
        manger_id: [{ required: true, message: "请选择经理", trigger: "blur" }],
         renshi_position:[{ required: true, message: "请选择人事职位", trigger: "blur" }],
        dian_id: [{ required: true, message: "请选择门店", trigger: "blur" }],
        position_time: [
          { required: true, message: "请选择入职时间", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
      },
      positionList: [
        {
          value: 1,
          label: "区域总监",
        },
        {
          value: 2,
          label: "店长",
        },
        {
          value: 3,
          label: "经理",
        },
        {
          value: 4,
          label: "经纪人",
        },
        {
          value: 5,
          label: "人事",
        },
      ], //职位列表
      areaList: [], //门店列表
      salaryList: [], //薪资模式列表
      region:[],//区域
      managerList:[],//经理列表
    };
  },
  methods: {
    cancelImg (type,index) {
      this[type].splice(index,1)
    },
    changePhone (index) {
      if (index == 0) {
        this.phoneList.push({phone:''})
      } else {
        this.phoneList.splice(index,1)
      }
    },
    //选择区域
    handleChangeCity () {
      let data = []
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.form.city = data.join('/')
      this.form.manger_id = ''
      this.getMenDian();
    },
    //上传图片
    uploadImg(e, index) {
      this.$axios.uploadFile(e.target.files[0], index).then((res) => {
        switch (index) {
          case 1:
            this.form.identity_image_z = res.fullurl;
            break;
          case 2:
            this.form.identity_image_f = res.fullurl;
            break;
          case 3:
            this.contract_images.push(res.fullurl);
             this.form.contract_images = this.contract_images.join(",");
            break;
          case 4:
            this.images.push(res.fullurl);
            this.form.images = this.images.join(",");
            break;
          default:
            break;
        }
      });
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.phoneList.length && this.phoneList.some(item=>item.phone)) {
            let phone = []
            this.phoneList.forEach(em=>{
              em ? phone.push(em.phone) : ''
            })
            this.form.relatives_mobile = phone.join(',')
          }
          this.$axios
            .staffUpdate({...this.form,
              birthday: this.form.birthday ? this.form.birthday / 1000 : "",
              position_time: this.form.position_time
                ? this.form.position_time / 1000
                : "",})
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              setTimeout(()=>{
                this.$router.go(-1)
              },800)
            });
        } else {
          return false;
        }
      });
    },
    //上传头像
    changeHead(e) {
      let file = e.target.files[0];
      let param = new FormData(); // 创建form对象
      param.append("file", file); // 通过append向form对象添加数据
      this.$axios.upload(param).then((res) => {
        this.form.image = res.data.fullurl;
      });
    },
    //获取门店列表
    getMenDian() {
      this.$axios.menDianC({
        city:this.form.city
      }).then((res) => {
        this.areaList = res.data;
      });
    },
    getUserList () {
      this.$axios.userList({position:3,city:this.form.city,dian_id:this.form.dian_id}).then(res=>{
        this.managerList = res.data
      })
    },
    //薪资
    getCompensation () {
      this.$axios.xinZi().then((res) => {
        this.salaryList = res.data;
      });
    },
    //获取详情
    getDetails () {

      this.$axios.staffDetail({ id: this.form.id }).then((res) => {
        res.data.salary = Number(res.data.salary)
        
        let {id,image,name,mobile,sex,age,identity,xian_address,native_place,relatives,position,dian_id,city,salary,identity_image_z,identity_image_f,renshi_position,manger_id} = res.data
        this.form = {...this.form,id,image,name,mobile,sex,age,identity,xian_address,native_place,relatives,position,dian_id,city,salary,identity_image_z,identity_image_f,renshi_position,manger_id}
        this.form.birthday = new Date(res.data.birthday) * 1
        this.form.position_time = new Date(res.data.position_time) * 1
        let relatives_mobile = []

        res.data.relatives_mobile.forEach(item=>{
          relatives_mobile.push({phone:item})
        })
        this.phoneList = relatives_mobile.length ? relatives_mobile : [{phone:''}]
        this.form.relatives_mobile = res.data.relatives_mobile.join(',')

        let data = res.data.city.split('/')
        let areaList = []
        this.regionList.forEach(em => {
         
          if (em.label == data[0]) {
             areaList.push(em.value)
             em.children.forEach(item=>{
               if (item.label == data[1]) {
                 areaList.push(item.value)
                 item.children.forEach(it=>{
                   if (it.label == data[2]) {
                     areaList.push(it.value)
                   }
                 })
               }
             })
          }
        });
        this.region = areaList

        let images = [];
        res.data.images.forEach((element) => {
          if (element) {
            images.push(element);
          }
        });
        this.images = images;
        this.form.images = images.join(',')
        let contract_images = [];
        res.data.contract_images.forEach((element) => {
          if (element) {
            contract_images.push(element);
          }
        });
        this.contract_images = contract_images;
        this.form.contract_images = contract_images.join(',')

        this.getMenDian();
        this.getUserList()
      });
    }
  },
  created() {
    this.form.id = this.$route.query.id;
    this.getDetails();
    this.getCompensation()
  },
};
</script>

<style lang="less" scoped>
.addStaff_box {
  padding: 30px 30px 60px 30px;
  .addStaff_ul {
    padding-right: 40px;
    li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      .staffDetails_ul_head {
        span {
          padding-left: 14px;
          position: relative;
          &::before {
            top: 50%;
            left: 0;
            content: "";
            transform: translateY(-50%);
            position: absolute;
            width: 4px;
            height: 20px;
            background: #3273f6;
          }
        }
      }
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /deep/ .el-form-item {
      &:first-child {
        width: 100%;
        display: flex;
        align-items: center;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
    .form_head {
      width: 64px;
      height: 64px;
      position: relative;
      img {
        cursor: pointer;
        border-radius: 50%;
      }
      input {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        width: 64px;
        height: 64px;
        display: block;
        cursor: pointer;
        position: absolute;
      }
    }
  }
  /deep/ .el-input {
    width: 300px;
  }
}
.identity_ul_li {
  display: flex;
  width: 50%;
}
.identity_txt {
  width: 100px;
  padding-top: 10px;
  color: #606266;
  font-size: 14px;
  margin-right: 20px;
  text-align: right;
}
.identity_ul_li_img {
  cursor: pointer;
  position: relative;
  img {
    width: 180px;
    height: 120px;
    border-radius: 4px;
  }
  input {
    top: 0;
    opacity: 0;
    width: 180px;
    height: 120px;
    cursor: pointer;
    position: absolute;
  }
}
.staffDetails_other {
  display: flex;
  .staffDetails_other_li {
    cursor: pointer;
    margin-right: 20px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 150px;
      height: 150px;
      border-radius: 4px;
    }
    .cancel {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
      }
    input {
      top: 0;
      opacity: 0;
      width: 150px;
      height: 150px;
      cursor: pointer;
      position: absolute;
    }
  }
}
.addStaff_footer {
  margin-top: 80px;
  padding: 0 120px;
  .el-button {
    width: 100%;
    height: 56px;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
  }
}
.addStaff_phone {
  .addStaff_phone_li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-left: 20px;
    }
    /deep/ .el-input {
      width: 256px;
    }
  }
}
</style>
